html {
    font-size: 100%;
    box-sizing: border-box;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: radial-gradient(34.69% 60.86% at 50% 50%, $bg-light-green 0%, $bg-dark-green 100%) center center fixed;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
}

nav {
    background-color: transparent;
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    

    ul {
        list-style: none;
        display: grid;
        grid-template-columns: 0.25fr 1.25fr 0.125fr 0.125fr;
        grid-template-rows: 1fr;
    }
}

h1 {
    font-size: 1.625rem;
    font-weight: normal;
    color: $dark-green;
    margin-left: 1rem;
    margin-top: 0.625rem;
} 

p {
    font-size: 0.875rem;
    color: $dark-green;
    font-weight: normal;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    //fmargin: 2.5rem 2rem;
}

.top-bar {
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-template-rows: 1fr;
}  

.top-left {
    grid-column: 1 / 2;
    justify-self: start;
    display: flex;
    flex-direction: column;
}

.top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    //grid-column: 3 / 4;
    justify-self: end;
}

.tpr-1 {
    grid-column: 3 / 4;
}

.tpr-2 {
    grid-column: 4 / 5;
}

.center-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-btn {
    display: block;
    height: 3.625rem;
    width: 3.625rem;
    border-radius: 50%;
    border: none;
    box-shadow: $elem-shadow;

    &:hover {
        cursor: pointer;
    }

    img {
        width: inherit;
        border-radius: 50%;
    }
}

.icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.w-bg {
    background-color: $greyish-white;
}

.b-bg {
    background-color: $blueish-green;
}

.line {
    border-bottom: 1.5px solid $dark-green-trans;
    display: block;
    margin: 0.625rem 1rem 1rem 1rem;
}

.small-line {
    min-width: inherit;
    border-bottom: 1.5px solid $dark-green;
    display: block;
    margin: 0rem 0.5rem;
    align-self: stretch;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: hsla(0, 0%, 0%, 0.6);
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-placeholder {
    position: relative;
    padding: 2rem;
    width: 100%;
    max-width: 640px;
    background-color: $greyish-white;
    border: 2px solid $dark-green;
    border-radius: 20px;
    box-shadow: $elem-shadow;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 1.75rem;
        color: $dark-green;
        font-weight: bold;
    }

    h3 {
        font-size: 1.5rem;
        color: $dark-green;
        font-weight: normal;
    }

    p {
        font-size: 1rem;
        text-align: center;
    }

    .warning-grid {
        display: grid;
        grid-template-columns: 0.25fr 1.75fr;
        grid-template-rows: 0.5fr 0.5fr;
        align-items: center;
        justify-items: center;
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 2.25rem;
        height: 2.25rem;
        background-color: $dark-green;
        border-radius: 50%;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 8px 8px 16px $dark-shadow, -8px -8px 16px $green-shadow;
    }

    .project-input {
        width: 327px;
        height: 60px;
        border: 2px solid $dark-green;
        border-radius: 5px;
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }

    .delete-input {
        width: 327px;
        height: 60px;
        border: 2px solid $dark-green;
        border-radius: 5px;
        margin: 0.25rem 0;
    }

    .project-info {
        margin: 0;
        font-size: 0.75rem;
    }

    .project-error {
        margin: 0;
        font-size: 0.75rem;
        color: $red;
    }

    .profile-info {
        grid-column: 1 / 3;
        margin-top: 1rem;
        line-height: 1.5rem;
    }

    .info-icon svg {
        width: 2rem;
    }

    .btn-placeholder {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
    }

    .submit {
        background-color: $dark-green;
        border: none;
        border-radius: 10px;
        padding: 12px 28px;
        margin-top: 2rem;
        color: $greyish-white;
        font-size: 1.25rem;
        box-shadow: 12px 12px 24px $dark-shadow, -12px -12px 24px $green-shadow;
    }

    .sqr-btn {
        width: 5.1875rem;
        height: 2.3125rem;
        border-radius: 5px;
        font-size: 1.125rem;
        border: none;
        
        box-shadow: $btn-shadow;
        margin-top: 10px;
    }

    .cancel-btn {
        //border: 2px solid $dark-green;
        background-color: $white;
        color: $dark-green;

        &:hover {
            cursor: pointer;
            border: 2px solid $dark-green;
            color: $dark-green;
        }
    }

    .ok-btn {
        //border: 2px solid $greyish-white;
        background-color: $dark-green;
        color: $greyish-white;

        &:hover {
            cursor: pointer;
            border: 2px solid $white;
        }
    }

    .del-btn {
        background-color: $red;
        color: $greyish-white;

        &:hover {
            cursor: pointer;
            border: 2px solid $white;
        }
    }
}