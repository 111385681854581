.profile-menu-logged-off {
    top: 7.65rem;
    width: 13.5rem;
    height: 6.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $greyish-white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    position: absolute;
    box-shadow: $elem-shadow;

    &::before {
        content: "";
        display: block;
        height: 1.625rem;
        width: 1.625rem;
        background-color: inherit;
        border: inherit;
        position: absolute;
        right: 1rem;
        top: -0.8rem;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0.25rem 0 0;
        box-shadow: $elem-shadow;
    }
}

.profile-menu-logged-in {
    top: 7.65rem;
    min-width: 13.5rem;
    height: 12.5rem;
    border-radius: 0.5rem;
    background-color: $greyish-white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    position: absolute;
    box-shadow: $elem-shadow;

    &::before {
        content: "";
        display: block;
        height: 1.625rem;
        width: 1.625rem;
        background-color: inherit;
        border: inherit;
        position: absolute;
        right: 1rem;
        top: -0.8rem;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0.25rem 0 0;
        box-shadow: $elem-shadow;
    }
}

button, .route-link {
    font-size: 1.375rem;
    color: $dark-green;

    &:hover {
        cursor: pointer;
    }
}

.astext {
    text-decoration: none;
    background: none;
    border: none;
}

p {
    font-size: 1.375rem;
    color: $dark-green;
}