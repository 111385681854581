@media only screen and (max-width: 750px) {
    .form-container {
        width: 26.25rem;
    }

    .form-banner {
        padding: 30px 0;
        font-size: 2.475rem;
    }

    .selector{
        a {
            padding: 0.6rem 0;
            font-size: 1.25rem;
        }
    }

    .form-placeholder {
        padding: 1.75rem 3.25rem 0 3.25rem;
    }

    .form {
        p, a {
            font-size: 0.75rem;
        }
    }

    .input-form {
        margin-top: 1.45rem;
    }

    .input-icon {
        width: 3rem;
        height: 3rem;

        svg {
            width: 60%;
            height: 60%;
        }
    }

    .form-input-text {
        font-size: 0.85rem;
    }

    .submit-form {
        font-size: 1.75rem;
    }
}


@media only screen and (max-width: 450px) {
    .form-container {
        width: 21.25rem;
    }

    .form-banner {
        padding: 20px 0;
        font-size: 2rem;
    }

    .selector{
        a {
            padding: 0.5rem 0;
            font-size: 1.125rem;
        }
    }

    .form-placeholder {
        padding: 1.25rem 2.75rem 0 2.75rem;
    }

    .form {
        p, a {
            font-size: 0.55rem;
        }
    }

    .input-form {
        margin-top: 1rem;
    }

    .input-icon {
        width: 2.5rem;
        height: 2.5rem;

        svg {
            width: 55%;
            height: 55%;
        }
    }

    .form-input-text {
        font-size: 0.75rem;
    }

    .submit-form {
        font-size: 1.25rem;

        svg {
            width: 10px;
            height: 17px;
        }
    }
}