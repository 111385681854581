.form-container {
    width: 32.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: $elem-shadow;
    border-radius: 25px;
}

.form-banner {
    background: $banner-green;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;  
    padding: 40px 0;  

    font-size: 2.875rem;
    color: $greyish-white;
    font-weight: 500;
    text-align: center;
}

.selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: $greyish-white;

    a {
        padding: 0.75rem 0;
        background: transparent;
        border: none;
        border-bottom: 2px solid $deselected-button;
        font-size: 1.375rem;
        color: $deselected-button;
        text-decoration: none;
        text-align: center;
    }

    .focused-btn {
        color: $dark-green;
        border-bottom: 2px solid $dark-green;
        font-weight: 500;
    }
}

.form-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $greyish-white;
    padding: 2rem 4.25rem 0 4.25rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    p, a {
        font-size: 0.75rem;
        text-align: center;
        margin: 1rem;
    }

    a {
        text-decoration: none;
        color: $dark-green;
    }
}

.input-form {
    width: inherit;
    display: flex;
    margin-top: 1.75rem;
}

.input-icon {
    width: 3.75rem;
    height: 3.75rem;
    background: $dark-green;
    border-radius: 4px 0 0 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-input-text {
   flex-basis: 100%;
   border: 2px solid $dark-green;
   font-size: 1rem;
   border-radius: 0 4px 4px 0;
}

.submit-form {
    background-color: $blueish-green;
    border: none;
    border-radius: 0 0 25px 25px;
    padding: 1.25rem;
    width: 100%;
    color: $white;
    font-size: 1.75rem;
    font-weight: 500;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}