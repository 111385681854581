.task-menu {
    top: 7.65rem;
    left: 1.5rem;
    width: 25rem;
    height: 48rem;
    border-radius: 1rem;
    border: none;
    background-color: $greyish-white;
    position: absolute;
    box-shadow: $elem-shadow;
    z-index: 1;

    &::before {
        content: "";
        display: block;
        height: 1.625rem;
        width: 1.625rem;
        background-color: inherit;
        border: inherit;
        position: absolute;
        left: 2rem;
        top: -0.8rem;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0.25rem 0 0;
        box-shadow: $elem-shadow;
    }

    p {
        margin: 1rem;
        font-size: 0.875rem;
    }

    .signUpLink {
        color: $blueish-green;
    }

    .task-input {
        width: 92%;//19.5rem;
        height: 3.3125rem;
        margin-left: 1rem;
        margin-right: 1rem;
        padding: 0.875rem;
        border: 1px solid $dark-green-trans;
        border-radius: 0.25rem;
    
        &:focus {
            border: 2px solid $dark-green;
            outline: none;
            color: $dark-green;
        }
    }
}

.logged-in {
    display: none;
}

.sub-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 1rem;

    .select-proj {
        font-size: 0.875rem;
        color: $dark-green;
        font-weight: normal;
        text-decoration: none;
        cursor: pointer;
    }

    .down-icon {
        display: inline-block;
        height: 10px;
        width: 12px;
        margin-left: 5px;
    }

    .gear-icon {
        cursor: pointer;
        justify-self: end;
        align-self: center;

        svg {
            height: 15px;
            width: 15px;
        }
    }
}

.sub-menu-title-div {
    display: flex;
    justify-content: flex-start;
}

.sub-menu-title {
    border: none;
    color: $dark-green;
    font-size: 1rem;
    background: transparent;
    font-family: inherit;
    overflow: hidden;

    resize: none;
    padding: 2px;
    height: 1.35rem;
    max-width: 13rem;

    &:focus {
        border: 2px solid $dark-green;
        outline: none;
        color: $dark-green;
        border-radius: 2px;
        background: $white;
    }

    &:hover {
        cursor: pointer;
    }
}

// Project Styles
.project-menu {
    width: 10.375rem;
    min-height: 3.25rem;
    background-color: $dark-green;
    position: absolute;
    margin-top: .25rem;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 1;
    box-shadow: 10px 10px 20px $dark-shadow, -10px -10px 20px $green-shadow;

    .projects {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: inherit;
        padding: 1px;
    }

    button {
        background: transparent;
        border: none;
        font-size: 0.8125rem;
        color: $greyish-white;
        padding: 0.3125rem;
        //width: inherit;
    }

    a {
        text-decoration: none;
        font-size: 0.8125rem;
        color: $greyish-white;
        margin-top: 0.2rem;
        //text-align: center;
    }

    .selected {
        border-radius: 0.3125rem 0.3125rem 0 0;
        background-color: $selected-white;
        //margin: 1px;
    }

    .add-proj {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1px;
        margin-bottom: 5px;
    }
}

.proj-line {
    border-bottom: 1.5px solid $greyish-white;
    display: block;
    width: 100%;
    //margin-top: 0.25rem;
    //margin-bottom: 0.15rem;
    //margin: 0.625rem 1rem 1rem 1rem;
}

.ml-text {
    margin-left: 0.5rem;
}

// Project Settings Styles
.project-settings {
    width: 10.375rem;
    min-height: 3.25rem;
    background-color: $dark-green;
    position: absolute;
    right: 1rem;
    border-radius: 0.3125rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1;
    box-shadow: 10px 10px 20px $dark-shadow, -10px -10px 20px $green-shadow;

    font-size: 0.8125rem;
    color: $greyish-white;
}


// Text Button Styles
.task-text-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}

.task-text-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    grid-column: 2 / 3;
    margin-top: 80px;
    font-size: 2rem;
    color: $white;
    text-shadow: $text-shadow;
    align-self: center;

    &:hover {
        cursor: pointer;
    }
}

.popup-left {
    grid-column: 1 / 2;
    justify-self: start;
    margin-left: 6.23%;
}


// To-Do styles
.todolist {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.delete-completes {
    text-decoration: none;
    font-size: 1rem;
    color: $dark-green;
    align-self: flex-end;
    margin-right: 1rem;
}

.todo-placeHolder {
    display: grid;
    grid-template-columns: 0.15fr 1.55fr 0.15fr 0.15fr;
    align-items: start;
    margin: 0 1rem;
}

.todo {
    position: absolute;
    grid-column: 1 / 2;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: relative;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}
  
.checkmark {
    position: absolute;
    top: 1.5px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $white;
    border: 2px solid $dark-green;
    border-radius: 5px;
}

.todo {
    &:hover input ~ .checkmark {
        background-color: $greyish-white;
    }

    input:checked .checkmark {
        background-color: $white;
    }
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.todo {
    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 3.5px;
        top: 0;
        width: 5px;
        height: 10px;
        border: solid $dark-green;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
  
.task-link {
    grid-column: 2 / 3;
    margin-right: 0.625rem;
    height: 1.375rem;
    align-self: center;

    
}

.task-name {
    
    border: none;
    color: $dark-green;
    font-size: 1rem;
    background: transparent;
    font-family: inherit;
    
    overflow: hidden;
    resize: none;
    padding: 2px;
    height: 1.375rem;
    width: 100%;

    &:focus {
        border: 2px solid $dark-green;
        outline: none;
        color: $dark-green;
        border-radius: 2px;
        background: $white;
    }

    &:hover {
        cursor: pointer;
    }
}

.selected-task {
    text-decoration: line-through;
}

.edit {
    text-decoration: none;
    justify-self: center;
    grid-column: 3 / 4;
    color: $dark-green;
}

.deleteIcon {
    grid-column: 4 / 5;
    height: auto;
    justify-self: end;
    text-decoration: none;
}
