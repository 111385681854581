.stage-placeholder {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0.375rem;
    background-color: $dark-green;
    border-radius: 3rem;
    justify-self: center;
    gap: 25px;
    box-shadow: $elem-shadow;
}

.stage-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    border-radius: 1.5rem;
    width: 8rem;
    height: 2.875rem;
    color: $white;
    font-size: 1rem;

    &:hover {
        cursor: pointer;
    }
}

.active-stage-btn {
    background-color: $blueish-green;
    color: $dark-green;
    box-shadow: $elem-shadow;
    font-weight: semi-bold;
}