@media only screen and (max-width: 1300px) {
    .task-text-btn {
        font-size: 1.8rem;
        margin-top: 100px;
    }

    .timer-container {
        margin-top: 4rem;
    }

    .timer-wrapper {
        height: 24.375rem;
        width: 24.375rem;
        font-size: 3.5rem;
    }

    .pill-btn {
        width: 8.25rem;
        height: 3rem;
    }
}

@media only screen and (max-width: 1000px) {
    .task-text-btn {
        font-size: 1.7rem;
        grid-column: 1 / 4;
        margin-top: 120px;
    }

    .timer-container {
        margin-top: 5rem;
    }

    .timer-wrapper {
        height: 20rem;
        width: 20rem;
        font-size: 2.75rem;
    }

    .pill-btn {
        width: 7.25rem;
        height: 2.625rem;
    }
}

@media only screen and (max-width: 850px) {
    .task-text-btn {
        font-size: 1.5rem;
        margin-top: 80px;
    }

    .timer-container {
        margin-top: 6rem;
    }

    .timer-wrapper {
        height: 16.25rem;
        width: 16.25rem;
        font-size: 2rem;
    }

    .pill-btn {
        width: 6.5rem;
        height: 2.3125rem;
        font-size: 0.875rem;
    }
}

@media only screen and (max-height: 1000px) {
    .task-text-btn {
        font-size: 1.5rem;
        margin-top: 60px;
    }

    .action-placeholder {
        margin-top: 4.5rem;
    }
}

@media only screen and (max-height: 850px) {
    .task-text-btn {
        font-size: 1.5rem;
        margin-top: 45px;
    }

    .action-placeholder {
        margin-top: 3.5rem;
    }
}