.action-placeholder {
    list-style: none;
    display: flex;
    justify-self: center;
    gap: 1.375rem;
    margin-top: 8.5rem;
    margin-bottom: 2rem;
}

.pill-btn {
    height: 3.625rem;
    width: 8.75rem;
    font-size: 1rem;
    border-radius: 2.5rem;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

.start-btn {
    background-color: $blueish-green;
    color: $dark-green;
    font-weight: semi-bold;
    box-shadow: $elem-shadow;
}

.pause-btn {
    background-color: $dark-green;
    color: $blueish-green;
    font-weight: semi-bold;
    box-shadow: $elem-shadow;
}

.restart-btn {
    box-shadow: $elem-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
}