input {
    padding-left: 0.5rem;
}

.settings-menu-logged-off {
    top: 7.65rem;
    width: 25.625rem;
    height: 9rem;
    border-radius: 0.75rem;
    background-color: $blueish-green;
    position: absolute;
    box-shadow: $elem-shadow;
    transform: translateX(4%);

    &::before {
        content: "";
        display: block;
        height: 1.625rem;
        width: 1.625rem;
        background-color: inherit;
        border: inherit;
        position: absolute;
        right: 2rem;
        top: -0.8rem;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0.25rem 0 0;
        box-shadow: $elem-shadow;
    }
    
    p {
        font-size: 1rem;
        margin-left: 1rem;
        margin-top: 2rem;
        color: $greyish-white;
    }

    a {
        color: $dark-green;
    }
}

.settings-menu-logged-in {
    top: 7.65rem;
    width: 25.625rem;
    height: 27rem;
    border-radius: 0.75rem;
    background-color: $blueish-green;
    position: absolute;
    box-shadow: $elem-shadow;
    transform: translateX(4%);
    z-index: 1;


    &::before {
        content: "";
        display: block;
        height: 1.625rem;
        width: 1.625rem;
        background-color: inherit;
        border: inherit;
        position: absolute;
        right: 2rem;
        top: -0.8rem;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0.25rem 0 0;
        box-shadow: $elem-shadow;
    }

    .stage-settings {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .stage-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            margin-bottom: 4px;
            color: $dark-green;
        }
    }

    input[type=number] {
        width: 75px;
        height: 22px;
        border-radius: 5px;
        border: 1px solid $dark-green-trans;

        &:focus {
            border: 1.5px solid $dark-green;
            outline: none;
            color: $dark-green;
        }
    }

    .break-form {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .notif-settings {
        margin-left: 1rem;
    }

    .switch-grid {
        display: flex;
        align-items: center;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 38px;
        height: 20px;
        border-radius: 50%;

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $slider-bg;
            border-radius: 34px;
            transition: .4s;
            box-shadow: $slider-bg-shadow;
    
            &::before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 1px;
                bottom: 1px;
                border-radius: 50%;
                background-color: $blueish-green;
                transition: .4s;
                box-shadow: $slider-ball-shadow;
            }
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: $dark-green;
            }
        
            &:checked + .slider:before {
                transform: translateX(18.5px);
            }
        }

    }
    
    .phase-div {
        margin-left: 1rem;
    }
    
    .set-btns {
        display: flex;
        justify-content: flex-end;
        margin: 0 1rem;
        gap: 1.125rem;
    }

    .sqr-btn {
        width: 5.1875rem;
        height: 2.3125rem;
        border-radius: 5px;
        border: none;
        font-size: 1.125rem;
        color: $white;
        box-shadow: $elem-shadow;
        margin-top: 10px;
    }

    .cancel-btn {
        background-color: $slider-bg;

        &:hover {
            cursor: pointer;
            border: 2px solid $dark-green;
            color: $dark-green;
        }
    }

    .save-btn {
        background-color: $dark-green;

        &:hover {
            cursor: pointer;
            border: 2px solid $white;
        }
    }

}