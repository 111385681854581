.timer-container {
    display: flex;
    flex:2;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.timer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30rem;
    width: 30rem;
    border-radius: 50%;
    color:$blueish-green;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #03312E;
    font-size: 4rem;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.25), -20px -20px 40px rgba(19, 75, 73, 0.25);
}

.timer {
    box-shadow: $elem-shadow;
}